import React, { useState, useEffect } from 'react';
import { Routes, Route, NavLink as RouterNavLink, useLocation, Navigate } from 'react-router-dom';

import { Card, CardHeader, CardBody, CardTitle, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { PendingSection } from './pending-section/PendingSection';
import { SentSection } from './sent-section/SentSection';
import { FailedSection } from './failed-section/FailedSection';

export const Notifications = () => {
	const { pathname: locationPathname } = useLocation();
	const [activeTab, setActiveTab] = useState('pending');

	useEffect(() => {
		if (locationPathname === `pending`) {
			setActiveTab('pending');
		} else if (locationPathname === `sent`) {
			setActiveTab('sent');
		}
	}, [locationPathname]);

	return (
		<div className="content">
			<Row>
				<Col md="12">
					<Card>
						<CardHeader>
							<CardTitle tag="h4">Notifications</CardTitle>
						</CardHeader>
						<CardBody>
							<div className="nav-tabs-navigation">
								<div className="nav-tabs-wrapper">
									<Nav id="tabs" role="tablist" tabs>
										<NavItem key={`pending-nav-item`}>
											<NavLink
												tag={RouterNavLink}
												to={`pending`}
												end
												className={activeTab === 'pending' ? 'active' : ''}
												onClick={(e) => setActiveTab('pending')}
											>
												Pending
											</NavLink>
										</NavItem>
										<NavItem key={`sent-nav-item`}>
											<NavLink
												tag={RouterNavLink}
												to={`sent`}
												end
												className={activeTab === 'sent' ? 'active' : ''}
												onClick={(e) => setActiveTab('sent')}
											>
												Sent
											</NavLink>
										</NavItem>
										<NavItem key={`failed-nav-item`}>
											<NavLink
												tag={RouterNavLink}
												to={`failed`}
												end
												className={activeTab === 'failed' ? 'active' : ''}
												onClick={(e) => setActiveTab('failed')}
											>
												Failed
											</NavLink>
										</NavItem>
									</Nav>
								</div>
							</div>
							<TabContent>
								<TabPane>
									<Routes>
										<Route path={`pending`} element={<PendingSection />} />
										<Route path={`sent`} element={<SentSection />} />
										<Route path={`failed`} element={<FailedSection />} />
										<Route index element={<Navigate to={`pending`} />} />
									</Routes>
								</TabPane>
							</TabContent>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
