import React, { useState, useEffect, useContext } from 'react';
import * as Sentry from '@sentry/react';

import { Alert, Button, ButtonGroup, Col, CardTitle, Input, Label, Form, FormGroup, UncontrolledTooltip } from 'reactstrap';

import { TabData } from './details-tab/TabData';
import toast from 'react-hot-toast';

import { setting, member } from '../../../../api';

import UserContext from '../../../UserContext';
import { FaSpinner } from 'react-icons/fa';
import { SettingsDataField } from 'api/settings/general';
import { MemberDB } from 'api/members';
import { MemberGroup } from 'api/settings/clearances';
import { UnauthorizedException } from 'api/exceptions/UnauthorizedException';
import { ForbiddenException } from 'api/exceptions/ForbiddenException';
import { InvalidParametersException } from 'api/exceptions/InvalidParametersException';

interface Props {
	uid: string;
	closeModalAction: (state: boolean) => void;
	closeModalControl: (state: boolean) => void;
}

export const DetailsTab = (props: Props) => {
	const { jwt, setDisplayUserLoginOverlay } = useContext(UserContext);
	const { uid, closeModalControl } = props;

	const [dynamicSettingsDataFields, setDynamicSettingsDataFields] = useState<Array<SettingsDataField>>([]);
	const [clearanceMemberGroupSettings, setClearanceMemberGroupSettings] = useState<Array<MemberGroup>>([]);
	const [primaryFieldUID, setPrimaryFieldUID] = useState<string | null>(null);
	const [data, setData] = useState<MemberDB>();
	const [unarchiveValidationError, setUnarchiveValidationError] = useState<string | null>(null);
	const [isEditDetailsMode, setIsEditDetailsMode] = useState(false);
	const [isEditDetailsSaving, setIsEditDetailsSaving] = useState(false);
	const [editModeOriginalData, setEditModeOriginalData] = useState<MemberDB>();
	const [inputFieldMultipleAddMode, setInputFieldMultipleAddMode] = useState<Array<string>>([]);
	const [canSave, setCanSave] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string>();

	useEffect(() => {
		const getContents = async () => {
			try {
				const settingsGeneralResp = await setting.general.get(jwt);
				setDynamicSettingsDataFields(settingsGeneralResp.data_fields);
				setPrimaryFieldUID(settingsGeneralResp.data_fields.filter((field) => field.primary === true)[0].uid);

				const settingsClearResp = await setting.clearances.get(jwt);
				setClearanceMemberGroupSettings(settingsClearResp.member_groups);

				const memberResp = await member.get(jwt, uid);

				setData(memberResp);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					setDisplayUserLoginOverlay(true);
				} else if (error instanceof ForbiddenException) {
					setErrorMessage(error.message);
				} else {
					console.error('error');
					console.error(error);
					Sentry.captureException(error);
					setErrorMessage('Failed to load');
				}
			}
		};

		getContents();
	}, []);

	useEffect(() => {
		let canSave = true;

		if (primaryFieldUID != null) {
			if (data![primaryFieldUID] == null || data![primaryFieldUID] === '') {
				canSave = false;
			}
		}

		if (inputFieldMultipleAddMode.length !== 0) {
			canSave = false;
		}

		setCanSave(canSave);
	}, [isEditDetailsMode, inputFieldMultipleAddMode, data]);

	function handleInputFieldChange(fieldName: string, newValue: any, dataType?: string, isPrimaryField?: boolean) {
		let newData = { ...data! };
		let val = newValue;

		if (newValue != null && newValue.length > 0 && dataType != null) {
			if (dataType === 'int') {
				if (Array.isArray(val)) {
					val = val.map((nonParsedVal) => {
						return parseInt(nonParsedVal, 10);
					});
				} else {
					val = parseInt(val, 10);
				}
			}
		}

		newData[fieldName] = val;

		setData(newData);

		// TODO: Implement primary field check
	}

	function handleInputFieldMultipleAddMode(detailsFieldUID: string, state: boolean) {
		let newInputFieldMultipleAddMode = [...inputFieldMultipleAddMode];

		const indexOf = newInputFieldMultipleAddMode.indexOf(detailsFieldUID);
		if (indexOf >= 0) {
			// Already exists
			if (state === false) {
				// Remove from list
				newInputFieldMultipleAddMode.splice(indexOf, 1);
			}
		} else {
			// Doesn't exist
			if (state === true) {
				// Add it to the list
				newInputFieldMultipleAddMode.push(detailsFieldUID);
			}
		}

		setInputFieldMultipleAddMode(newInputFieldMultipleAddMode);
	}

	function toggleEditModeClick() {
		return Promise.resolve()
			.then(() => {
				closeModalControl(!isEditDetailsMode);
			})
			.then(() => {
				if (isEditDetailsMode === true) {
					// We are currently editing
					const origData = editModeOriginalData;
					setIsEditDetailsMode(false);
					setData(origData);
					setEditModeOriginalData(undefined);
				} else {
					// We are not editing
					setIsEditDetailsMode(true);
					setEditModeOriginalData(JSON.parse(JSON.stringify(data)));
				}
			});
	}

	async function handleEditButtonSaveClick() {
		setErrorMessage(undefined);
		setIsEditDetailsSaving(true);

		try {
			// TODO: Handle use case where BSA-ID (uid) changes
			const response = await member.update(jwt, uid, data!);
			toast.success('Successfully saved');

			setIsEditDetailsMode(false);
			setEditModeOriginalData(undefined);
			setIsEditDetailsSaving(false);

			closeModalControl(false);
		} catch (error) {
			if (error instanceof InvalidParametersException) {
				toast.error(error.message);
			} else {
				console.error(error);
				setErrorMessage((error as any).message);
				setIsEditDetailsSaving(false);
				toast.error('Failed to save');
				Sentry.captureException(error);
			}
		}
	}

	async function handleArchiveButtonClick(forceUnarchive = false) {
		if (data == null) {
			toast.error('Unable to Archive. Please try again.');
			return;
		}

		setUnarchiveValidationError(null);

		if (data.archived == null) {
			try {
				const response = await member.archive(jwt, uid);
				const newData = { ...data! };
				newData.archived = new Date();
				setData(newData);

				// Set the state of everything
				setIsEditDetailsMode(false);
				setIsEditDetailsSaving(false);
				setEditModeOriginalData(undefined);
				toast.success('Member archived');
			} catch (error) {
				// TODO: Handle better
				console.error('error');
				console.error(error);
				toast.error('Failed to archive');
				Sentry.captureException(error);
			}
		} else {
			try {
				await member.restore(jwt, uid, forceUnarchive);

				// Update the data
				if (forceUnarchive) {
					const memberResp = await member.get(jwt, uid);
					setData(memberResp);
				} else {
					const newData = { ...data! };
					newData.archived = null;
					setData(newData);
				}
				toast.success('Member unarchived');
			} catch (error) {
				if (error instanceof InvalidParametersException) {
					setUnarchiveValidationError(error.message);
				} else {
					// TODO: Handle better
					console.error('error');
					console.error(error);
					toast.error('Failed to unarchive');
					Sentry.captureException(error);
				}
			}
		}
	}

	const memberClearanceGroupName = clearanceMemberGroupSettings.filter((memberGroup) => memberGroup.uid === data?.member_group)[0];

	return (
		<div className="details-tab">
			{!isEditDetailsMode ? (
				<>
					<ButtonGroup className="pull-right">
						<Button
							id="member-details-overlay-details-tab-edit-mode-button"
							color="info"
							size="sm"
							className="btn-icon edit-mode-button"
							disabled={data != null && data.archived != null}
							onClick={() => toggleEditModeClick()}
						>
							<i className="fa fa-pencil" />
						</Button>
						{data != null && data.archived != null && (
							<Button
								id="member-details-overlay-details-tab-archive-button"
								color="secondary"
								size="sm"
								className="btn-icon archive-button"
								onClick={() => handleArchiveButtonClick()}
							>
								<i className="fa fa-archive" />
							</Button>
						)}
						{(data == null || data.archived == null) && (
							<Button
								id="member-details-overlay-details-tab-archive-button"
								color="secondary"
								size="sm"
								className="btn-icon archive-button"
								onClick={() => handleArchiveButtonClick()}
							>
								<i className="fa fa-archive" />
							</Button>
						)}
					</ButtonGroup>

					<UncontrolledTooltip delay={0} target={'member-details-overlay-details-tab-edit-mode-button'}>
						Edit Member
					</UncontrolledTooltip>
					<UncontrolledTooltip delay={0} target={'member-details-overlay-details-tab-archive-button'}>
						{data != null && data.archived == null ? 'Archive' : 'Unarchive'} Member
					</UncontrolledTooltip>
				</>
			) : (
				<>
					<Button color="link" size="sm" className="pull-right edit-mode-button" onClick={() => toggleEditModeClick()} disabled={isEditDetailsSaving}>
						Cancel
					</Button>
					<Button
						color="info"
						size="sm"
						className="pull-right edit-mode-button"
						onClick={() => handleEditButtonSaveClick()}
						disabled={!canSave || isEditDetailsSaving}
					>
						{!isEditDetailsSaving ? 'Save' : <FaSpinner size={12} className="fa-spin" />}
					</Button>
				</>
			)}
			<CardTitle tag="h4" style={{ marginTop: 0, marginBottom: '.5rem' }}>
				{!isEditDetailsMode ? `Details` : `Edit`}
			</CardTitle>
			{errorMessage != null ? <Alert color="danger">{errorMessage}</Alert> : <></>}
			{data != null && data.archived != null ? (
				unarchiveValidationError != null ? (
					<Alert color={'danger'}>
						{unarchiveValidationError}
						<Button
							color="secondary"
							size="sm"
							className="pull-right force-unarchive-button"
							outline
							onClick={() => handleArchiveButtonClick(true)}
							disabled={!canSave || isEditDetailsSaving}
						>
							Force
						</Button>
					</Alert>
				) : (
					<Alert color={'secondary'}>
						Member is Archived
						{unarchiveValidationError != null ? (
							<>
								<Button
									color="info"
									size="sm"
									className="pull-right edit-mode-button"
									onClick={() => handleEditButtonSaveClick()}
									disabled={!canSave || isEditDetailsSaving}
								>
									{unarchiveValidationError}
								</Button>
							</>
						) : (
							<></>
						)}
					</Alert>
				)
			) : (
				<></>
			)}
			<Form className="edit-form">
				{dynamicSettingsDataFields != null ? (
					dynamicSettingsDataFields.map((field) => {
						return (
							<TabData
								key={field.uid}
								field={field}
								value={data != null ? data[field.uid] : null}
								isEditMode={isEditDetailsMode}
								handleInputFieldChange={handleInputFieldChange}
								handleInputFieldMultipleAddMode={handleInputFieldMultipleAddMode}
							/>
						);
					})
				) : (
					<></>
				)}
				<FormGroup row key={`member-details-overlay-details-tab-data-member-group`}>
					<Label for={'memberGroup'} sm={3}>
						<strong>Member Group</strong>
					</Label>
					<Col sm={9}>
						{!isEditDetailsMode ? (
							memberClearanceGroupName != null ? (
								memberClearanceGroupName.name
							) : (
								'Unknown'
							)
						) : (
							<Input
								type={'select'}
								name={'memberGroup'}
								id={'memberGroup'}
								placeholder={'Clearance Member Group'}
								onChange={(e) => {
									handleInputFieldChange('member_group', e.target.value);
								}}
								defaultValue={data?.member_group}
							>
								<option value={null as any}>- Select Member Group -</option>
								{clearanceMemberGroupSettings.map((memberGroup) => {
									return (
										<option key={memberGroup.uid} value={memberGroup.uid}>
											{memberGroup.name}
										</option>
									);
								})}
							</Input>
						)}
					</Col>
				</FormGroup>
			</Form>
		</div>
	);
};
