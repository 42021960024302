import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { Button, Table, UncontrolledTooltip, Alert } from 'reactstrap';
import toast from 'react-hot-toast';

import { notification } from '../../../api';
import UserContext from '../../UserContext';

import { NotificationDetailsOverlay } from '../helpers/NotificationDetailsOverlay';
import { FaSpinner } from 'react-icons/fa';
import { NotificationDB } from 'api/notifications';
import { ForbiddenException } from 'api/exceptions/ForbiddenException';
import { FailedToFetchException } from 'api/exceptions/FailedToFetchException';

export const FailedSection = () => {
	const { jwt } = useContext(UserContext);

	const params = useParams<{ notificationID: string }>();
	const notificationID = params.notificationID;

	const [isLoaded, setIsLoaded] = useState(false);
	const [data, setData] = useState<Array<NotificationDB>>([]);
	const [tableRecordDetailsData, setTableRecordDetailsData] = useState<NotificationDB>();

	useEffect(() => {
		const getContents = async () => {
			try {
				const response = await notification.getFailed(jwt);

				setData(response);
				setIsLoaded(true);
			} catch (error) {
				if (error instanceof ForbiddenException) {
					toast.error(error.message);
				} else if (error instanceof FailedToFetchException) {
					toast.error(error.message);
				} else {
					console.error('error');
					console.error(error);
					Sentry.captureException(error);
					toast.error('Failed to load');
				}
			}
		};

		getContents();
	}, []);

	useEffect(() => {
		if (notificationID != null) {
			if (data != null) {
				const details = data.filter((row) => row.docID === notificationID)[0];
				setTableRecordDetailsData(details);
			}
		} else {
			setTableRecordDetailsData(undefined);
		}
	}, [isLoaded, notificationID]);

	const handleDetailsOnCloseClick = (removeRecord = false) => {
		if (removeRecord === true) {
			let rowToRemove = null;

			if (data != null) {
				data.forEach((row, index) => {
					if (row.docID === tableRecordDetailsData!.docID) {
						rowToRemove = index;
					}
				});

				if (rowToRemove != null) {
					const newData = [...data];
					newData.splice(rowToRemove, 1);
					setData(newData);
				}
			}
		}

		setTableRecordDetailsData(undefined);
	};

	return (
		<>
			<NotificationDetailsOverlay show={tableRecordDetailsData != null} data={tableRecordDetailsData!} onClose={handleDetailsOnCloseClick} />
			{isLoaded ? (
				data != null && data.length > 0 ? (
					<Table responsive style={{ marginBottom: 0 }}>
						<thead className="text-primary">
							<tr>
								<th className="text-left">Sender</th>
								<th className="text-center">Date</th>
								<th className="text-center">To</th>
								<th className="text-center">Subject</th>
								<th className="text-right">Actions</th>
							</tr>
						</thead>
						<tbody>
							{data.map((row, key) => {
								const parsedTo = row.to != null ? row.to.split(',') : [];
								return (
									<tr key={`notifications-failed-section-data-field-${row.docID}`}>
										<td>{row.sender != null ? row.sender : 'Default'}</td>
										<td className="text-center">{(row as any).created}</td>
										<td className="text-center">{parsedTo.join(', ')}</td>
										<td className="text-center">{row.subject}</td>
										<td className="text-right">
											<Button
												className="btn-icon"
												color="info"
												id={`notifications-failed-section-data-field-${key}-details`}
												size="sm"
												type="button"
												onClick={() => setTableRecordDetailsData(row)}
											>
												<i className="fa fa-file" />
											</Button>
											<UncontrolledTooltip delay={0} target={`notifications-failed-section-data-field-${key}-details`}>
												View Details
											</UncontrolledTooltip>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				) : (
					<Alert color="secondary">
						<span>No records</span>
					</Alert>
				)
			) : (
				<FaSpinner size={12} color="#ccc" className="fa-spin" />
			)}
		</>
	);
};
